





















import { incidenciaAllDto } from "@/shared/dtos/incidencias/incidenciaAllDto";
import { Component, PropSync, Vue } from "vue-property-decorator";
@Component({
  components: {
    IncidenciasKanban: () =>
      import("@/views/proyectos/incidencias/incidenciasKanban.vue"),
    IncidenciasLista: () =>
      import("@/views/proyectos/incidencias/incidenciasLista.vue"),
  },
})
export default class incidenciasHandler extends Vue {
  @PropSync("id_proyecto", { default: -1 }) id_proyectosync!: number;
  @PropSync("id_tarea", { default: -1 }) id_tareasync!: number;
  @PropSync("incidencias", { default: [] })
  incidencias_list!: incidenciaAllDto[];
  public view_incidencias = "kanban";
}
